(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/middleware-grassroots/assets/grassroots.js') >= 0) return;  svs.modules.push('/components/payment/middleware-grassroots/assets/grassroots.js');

'use strict';

const {
  MiddlewarePaymentError
} = svs.components.payment.common.error;
const {
  logger,
  services
} = svs.components.payment.common;
const displayGrasrootsToaster = async () => new Promise(resolve => {
  var _svs$components$dialo;
  new svs.sport.GrassrootsToaster().show({
    onDismiss: resolve,
    onSuccess: resolve
  });

  const infoType = svs.sport.GrassrootsToaster().INFO_TYPE;
  const grassRootsToasterOptions = (_svs$components$dialo = svs.components.dialog) === null || _svs$components$dialo === void 0 || (_svs$components$dialo = _svs$components$dialo.api) === null || _svs$components$dialo === void 0 || (_svs$components$dialo = _svs$components$dialo.queue) === null || _svs$components$dialo === void 0 || (_svs$components$dialo = _svs$components$dialo.areas) === null || _svs$components$dialo === void 0 || (_svs$components$dialo = _svs$components$dialo.toaster) === null || _svs$components$dialo === void 0 || (_svs$components$dialo = _svs$components$dialo.items) === null || _svs$components$dialo === void 0 || (_svs$components$dialo = _svs$components$dialo.find(item => {
    var _item$options;
    return ((_item$options = item.options) === null || _item$options === void 0 ? void 0 : _item$options.infoType) === infoType;
  })) === null || _svs$components$dialo === void 0 ? void 0 : _svs$components$dialo.options;
  if (grassRootsToasterOptions) {
    grassRootsToasterOptions.convertToPopup = false;
  }
});
const grassroots = _ref => {
  let {
    payment
  } = _ref;
  return async next => {
    const {
      result
    } = payment.paymentResponse;
    const paidItems = result.getPaidPayableItems();
    if ((paidItems === null || paidItems === void 0 ? void 0 : paidItems.length) > 0) {
      try {
        const receivers = await services.fetchSponsorships();
        if (!(receivers !== null && receivers !== void 0 && receivers.length)) {
          logger.info('Showing grassroots toaster for no sponsorships.');
          await displayGrasrootsToaster();
        }
      } catch (error) {
        logger.debug(error.message, error);
        return next(new MiddlewarePaymentError({
          clientCode: MiddlewarePaymentError.ErrorCodes.GRASSROOT
        }));
      }
    }
    next();
  };
};
setGlobal('svs.components.payment.middleware.grassroots', grassroots);

 })(window);